import styled from 'styled-components';
import config from '../../components/config';
import Layout from '../../components/atoms/layout';

export default styled(Layout)`
  font-size: 2rem;
  line-height: 1.5;

  p {
    margin-bottom: 3.2rem;
  }

  .company-logos {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    & > img {
      margin: 0 3.5% 2%;
      width: 18%;
    }
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    p {
      font-size: 1.6rem;
    }

    .company-logos {
      justify-content: space-between;

      & > img {
        margin: 0 2% 2%;
        width: 40%;
      }
    }
  }
`;
