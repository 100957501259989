import styled from 'styled-components';
import config from '../../components/config';
import Layout from '../../components/atoms/layout';

export default styled(Layout)`
  align-items: center;
  background: ${config.palette.white};
  border-radius: ${config.borderRadius};
  box-shadow: 0 3.2rem 6.4rem rgba(0, 0, 0, 0.1);
  color: ${config.palette.darkBlue};
  display: flex;
  flex-wrap: wrap;
  font-size: 1.6rem;
  justify-content: space-between;
  line-height: 2.4rem;
  margin: -6rem calc((100% - 129.6rem) / 2) 0;
  padding: 2.6rem 3.2rem;
  width: 129.6rem;
  z-index: 100;

  &.active {
    position: fixed;
    margin-top: 0;
    top: 3rem;
  }

  .content-wrapper {
    max-width: 42rem;
  }

  & > a {
    background: ${config.palette.lightBlue};
    border-radius: ${config.borderRadius};
    color: ${config.palette.white};
    cursor: pointer;
    font-size: 2rem;
    font-weight: 700;
    padding: 1.6rem 8.8rem;
    text-decoration: none;
    transition: 0.3s ease-in-out background;

    &:hover {
      background: ${config.palette.lightBlueHover};
    }
  }

  @media (max-width: ${config.resolutions.desktopS}) {
    margin-left: calc((100% - 112rem) / 2);
    margin-right: calc((100% - 112rem) / 2);
    width: 112rem;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    bottom: 5.6rem;
    color: ${config.palette.darkBlueTransparent('0.6')};
    font-size: 1.3rem;
    line-height: 2.4rem;
    margin: 0 1.2rem;
    max-width: calc(100% - 2.4rem);
    padding: 1.6rem;
    position: fixed;
    text-align: center;
    width: 100%;

    &.active {
      bottom: 5.6rem;
      top: auto;
    }

    & > a {
      font-size: 1.6rem;
      font-weight: 400;
      margin-top: 0.8rem;
      padding: 2.4rem;
      text-align: center;
      width: 100%;
    }
  }
`;
