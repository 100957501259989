import React from 'react';
import Helmet from 'react-helmet';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

import { defaultLanguage } from '../../i18n/config';
import { LandingHreflangs } from '../../template/Landing/interfaces';

interface Props {
  t: TFunction;
  locale: string;
  url: string;
  title: string;
  description: string;
  deindexed?: boolean;
  hreflangs: LandingHreflangs;
}

const IRONHACK_URL = process.env.IRONHACK_URL;

const generateHrefLang = (
  hrefLink: string,
  languageCode: string,
  index: number
): JSX.Element => (
  <link
    href={`${IRONHACK_URL}${hrefLink}`}
    hrefLang={languageCode}
    key={`hrefLang-${index}`}
    rel="alternate"
  />
);

const SEOLandingPage: React.SFC<any> = (props: Props) => {
  const { locale, url, title, description, hreflangs, deindexed } = props;

  const defaultHrefLang =
    hreflangs &&
    hreflangs[defaultLanguage] &&
    generateHrefLang(hreflangs[defaultLanguage], 'X-Default', 0);

  const languages: Record<string, string> = {
    en: 'en-US',
    es: 'es-ES',
    fr: 'fr-FR',
    de: 'de-DE',
    nl: 'nl-NL',
    pt: 'pt-PT',
    br: 'pt-BR',
  };

  return (
    <Helmet
      htmlAttributes={{
        lang: languages[locale],
      }}
      meta={[
        {
          name: 'og:site_name',
          content: IRONHACK_URL,
        },
        {
          name: 'og:title',
          content: title,
        },
        {
          name: 'og:description',
          content: description,
        },
        {
          name: 'description',
          content: description,
        },
      ]}
      title={title}
    >
      {!_.isEmpty(hreflangs) &&
        Object.entries(hreflangs).reduce(
          (
            links: JSX.Element[],
            [locale, hreflang]: string[]
          ): JSX.Element[] => {
            links.push(
              generateHrefLang(hreflang, languages[locale], links.length)
            );
            return links;
          },
          defaultHrefLang ? [defaultHrefLang] : []
        )}
      {deindexed && <meta content="noindex, nofollow" name="robots" />}
    </Helmet>
  );
};

export default withTranslation()(SEOLandingPage);
