import styled from 'styled-components';
import Layout from '../../components/atoms/layout';
import config from '../../components/config';

export default styled(Layout)`
  align-items: flex-start;
  background: ${props => props.background};
  color: ${config.palette.white};
  display: flex;
  font-size: 2rem;
  justify-content: space-between;
  line-height: 150%;
  margin-bottom: 8rem;
  padding-bottom: 6.4rem;
  padding-top: 6.4rem;

  & > img {
    width: 40rem;
  }

  & > div {
    width: calc(100% - 44.8rem);
  }

  strong {
    display: block;
    font-family: 'Gelion-SemiBold';
    font-size: 4.8rem;
    line-height: 130%;
    margin-bottom: 1.6rem;
  }

  span {
    display: block;
    font-size: 1.8rem;
    margin-bottom: 3.6rem;
    text-transform: uppercase;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    flex-wrap: wrap;
    font-size: 1.6rem;

    & > img,
    & > div {
      width: 100%;
    }

    strong {
      font-size: 3rem;
      margin-bottom: 0;
      margin-top: 3.2rem;
    }

    span {
      font-size: 1.6rem;
    }
  }
`;
