import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from './theme/index';

import Component from './component';

function ThemeWrapper(props: any): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <Component {...props} />
    </ThemeProvider>
  );
}

export default ThemeWrapper;
