import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { useTranslation } from 'react-i18next';
import useTheme from '../../../hooks/useTheme';

import Card from '../../../components/atoms/layout/Card';
import ReviewScore from '../../../components/RetinaReviewScore';
import RetinaApplyForm from './Form';

function RetinaApply(props: any): JSX.Element {
  const { cohorts } = props;
  const { t } = useTranslation(['retina-landing']);
  const {
    layout: { desktop, mobile }
  } = useTheme();

  return (
    <Flex
      id="apply-form"
      width={[mobile.width, '100%']}
      flexDirection="row"
      justifyContent="center"
    >
      <Card
        width={[mobile.width, 1296]}
        borderRadius={['', 'card']}
        bg={['', '#EEEDFF']}
        mb={[0, 600]}
      >
        <Flex
          flexWrap={['nowrap', 'wrap']}
          flexDirection={['column', 'row']}
          height={['']}
          mb={[0, 7]}
        >
          <Box
            py={[5, 0]}
            px={[mobile.padding, '']}
            bg={['#EEEDFF', '']}
            width={[1, 512]}
            ml={[0, 8]}
            mt={[0, 7]}
          >
            <Text width={[1, 350]} as="h3" variant="title1" color="darkBlue">
              {`Ready to start your path in tech?`}
            </Text>
            <Text as="p" mt={4} variant="body4" color="darkBlues.40">
              {`With Zomato’s full and partial scholarships you’re just a few steps away from pursuing the career of your dreams. Choose the course that’s right for you and join a community of over 8,000 students who have changed their lives through our tech bootcamps.Study 9 weeks full-time, or 24 weeks part-time and  start your path in Tech!`}
            </Text>
            <Text as="p" mt={4} variant="body4" color="darkBlues.40">
              {`Here’s the scholarship process: 
1) Apply online, 2) Technical Assessment, 3) Personal Interview. 
Last day to apply is May 5th and winners will be announced by May10th - Make sure you apply now to be able to complete the process.Study 9 weeks full-time, or 24 weeks part-time and let’s start writing your story!`}
            </Text>
            <Text as="p" mt={4} variant="body4" color="darkBlues.40">
              {`Here’s the scholarship process: 01 Apply online, 02 Technical Assessment, 03 Personal Interview. 
Winners will be announced by May10th - Make sure you apply now to be able to complete the process`}
            </Text>
          </Box>
          <Box
            px={[mobile.padding, '']}
            width={[1, 600]}
            height={['', 200]}
            ml={[0, 7]}
            mt={[0, 6]}
          >
            <Card
              bg={'white'}
              boxShadow={['none', '0px 4px 8px rgba(0, 0, 0, 0.08)']}
              borderRadius={[0, 5]}
              px={[0, 6]}
              pt={6}
              pb={5}
            >
              <RetinaApplyForm cohorts={cohorts} />
            </Card>
          </Box>
          <Box
            px={[mobile.padding, '']}
            width={[300, 333]}
            ml={[0, 8]}
            mt={[9, 6]}
          >
            <ReviewScore />
          </Box>
        </Flex>
      </Card>
    </Flex>
  );
}

export default RetinaApply;
