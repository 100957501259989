import React from 'react';
import classNames from 'classnames';
import ApplyForm from '../../components-landing/ApplyForm';
import Header from '../../components-landing/Header';
import Navbar from '../../components-landing/Navbar';
import Video from '../../components-landing/VideoContainer';
import ValueProposition from '../../components-landing/ValueProps';
import TextContainer from '../../components-landing/TextContainer';
import OurCourses from '../../components-landing/OurCourses';
import ApplicationProcess from '../../components-landing/ApplicationProcess';
import Testimonials from '../../components-landing/Testimonial';
import CompanyTestimonial from '../../components-landing/CompanyTestimonial';
import CareerSupport from '../../components-landing/CareerSupport';
import Faq from '../../components-landing/Faq';
import Campus from '../../components-landing/Campus';
import MoreInfo from '../../components-landing/MoreInfo';
import Footer from '../../components-landing/Footer';
import Layout from './styles';
import { PropsLanding } from './interfaces';
import { graphql } from 'gatsby';
import withI18next from '../../i18n/TemplateI18n';

import SeoLandingPage from '../../components-landing/SEOLandingPage';

const LandingPage: React.SFC<any> = (props: PropsLanding) => {
  const {
    url,
    cohorts,
    breadcrumb,
    customColor,
    partnershipType = '',
    isIsa = false,
    hasExtraText = false,
    header,
    navbar,
    video,
    valueProposition,
    extraText,
    courses,
    applicationProcess,
    testimonials,
    companyTestimonial,
    careerSupport,
    faq,
    campus,
    applyForm,
    moreInfo,
    termsAndConditions,
    seo,
    hreflangs,
    lang
  } = props.pageContext;
  const layoutClass = classNames({
    'partnership-1': partnershipType === 'partnership-1',
    'partnership-2': partnershipType === 'partnership-2',
    'is-isa': isIsa,
    'extra-text': hasExtraText
  });

  return (
    <>
      <SeoLandingPage locale={lang} url={url} hreflangs={hreflangs} {...seo} />
      <Layout className={layoutClass} customColor={customColor}>
        <Header
          {...header}
          isPartnership={partnershipType === 'partnership-2'}
        />
        <Navbar {...navbar} />
        <Video {...video} />
        <ValueProposition {...valueProposition} />
        {extraText && <TextContainer {...extraText} />}
        <OurCourses {...courses} />
        {applicationProcess && <ApplicationProcess {...applicationProcess} />}
        {testimonials && <Testimonials {...testimonials} />}
        {companyTestimonial && <CompanyTestimonial item={companyTestimonial} />}
        {careerSupport && <CareerSupport {...careerSupport} />}
        <Faq {...faq} />
        {campus && <Campus {...campus} />}
        {applyForm && <ApplyForm cohorts={cohorts} />}
        {moreInfo && <MoreInfo {...moreInfo} />}
        <Footer
          breadcrumb={{ text: breadcrumb, url }}
          termsAndConditions={termsAndConditions}
          isIsa={isIsa}
        />
      </Layout>
    </>
  );
};

export const query = graphql`
  query($locale: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: {
          regex: "/(breadcrumbs)|(banner)|(retina-landing)|(application-form)|(languages)/"
        }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next()(LandingPage);
