import React from 'react';
import Wrapper from './styles';
import {
  LandingCareerSupport,
  CompanyLogo
} from '../../template/Landing/interfaces';

function CareerSupport(props: LandingCareerSupport) {
  const { title, text, logos } = props;

  function getText() {
    if (typeof text === 'string') {
      return <p>{text}</p>;
    }

    return text.map((paragraph: string, index: number) => (
      <p
        key={`career-support-text-${index}`}
        dangerouslySetInnerHTML={{
          __html: paragraph
        }}
      />
    ));
  }

  function getLogos() {
    return (
      logos &&
      logos.map((logo: CompanyLogo, index: number) => (
        <img
          key={`career-support-logo-${index}`}
          src={`/landings/career-support/${logo.image}`}
          alt={logo.name}
        />
      ))
    );
  }

  return (
    <Wrapper>
      <h3>{title}</h3>
      <React.Fragment>{getText()}</React.Fragment>
      <div className="company-logos">{getLogos()}</div>
    </Wrapper>
  );
}

export default CareerSupport;
