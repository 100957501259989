import React from 'react';
import Layout from './styles';
import {
  LandingTestimonial,
  TestimonialItem
} from '../../template/Landing/interfaces';

function Testimonial(props: LandingTestimonial): JSX.Element {
  const { title, items } = props;

  function generateTestimonialItems(): Array<JSX.Element> {
    return items.map((item: TestimonialItem) => (
      <div key={item.name} className="testimonial-item">
        <img src={`/landings/${item.image}`} alt={item.name} />
        <div>
          {item.description}
          <strong>
            {item.name} - {item.position}
          </strong>
        </div>
      </div>
    ));
  }

  return (
    <Layout>
      <h3>{title}</h3>
      <article>{generateTestimonialItems()}</article>
    </Layout>
  );
}

export default Testimonial;
