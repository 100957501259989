import _ from 'lodash/fp';
import { FORM_ERROR } from 'final-form';
import { navigate } from 'gatsby';
import moment from 'moment';
import { Flex, Box, Text } from 'rebass/styled-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'react-final-form';

import { ApplyFormProps, SelectOption, StringMap } from './interfaces';
import Cohort from '../../../../components/interfaces/Cohorts';
import { Error } from './styles';
import { submitApplyForm } from './submission';
import { getLinkWithoutDomain } from '../../../../lib/links';

import InputText from '../../../../components/Forms/RFF-fields/InputText';
import InputCheckbox from '../../../../components/Forms/RFF-fields/InputCheckbox';
import InputSelect from '../../../../components/Forms/RFF-fields/InputSelect';
import InputPhone from '../../../../components/Forms/RFF-fields/InputPhone';
import SubmitButton from '../../../../components/atoms/buttons/Button';

const ApplyFormLanding: React.FunctionComponent<any> = (
  props: ApplyFormProps
) => {
  const { cohorts } = props;
  const { t, i18n } = useTranslation([
    'application-form',
    'breadcrumbs',
    'languages'
  ]);

  moment.locale(i18n.language);
  const languageNames = useMemo(() => getLanguageNames(t), [i18n.language]);

  const cohortOptions = useMemo(
    () => buildCohortOptions(languageNames, cohorts),
    [languageNames, cohorts]
  );
  const courseOptions = useMemo(() => buildCourseOptions(cohortOptions), [
    cohortOptions
  ]);

  const redirectURL = getLinkWithoutDomain('zomatoApplyThanks');

  return (
    <Form
      onSubmit={async values => {
        const cohort = cohorts.find(c => c.id === values.edition);
        if (!cohort) return { [FORM_ERROR]: 'Missing required fields' };
        try {
          values.campus = 'lis';
          await submitApplyForm(values, cohort, i18n.language);
          navigate(redirectURL);
        } catch (e) {
          return {
            [FORM_ERROR]:
              'There has been an error with your application, please try again in a few minutes...'
          };
        }
      }}
      validate={validate}
      render={({ handleSubmit, values, submitting, error, submitError }) => {
        const campus = 'lis';
        const { course } = values;
        return (
          <form onSubmit={handleSubmit}>
            <Flex flexWrap="wrap" justifyContent="space-between">
              <Box width={[1, 1 / 2]} pr={[0, 3]}>
                <Field
                  name="firstName"
                  label="Name"
                  component={InputText}
                  variant="retina"
                />
              </Box>
              <Box width={[1, 1 / 2]} pl={[0, 3]} mt={[5, 0]}>
                <Field
                  name="lastName"
                  label="Last Name"
                  component={InputText}
                  variant="retina"
                />
              </Box>
              <Box width={1} mt={6}>
                <Field
                  name="email"
                  label="Email"
                  component={InputText}
                  variant="retina"
                />
              </Box>
              <Box width={1} mt={6}>
                <Field
                  name="phone"
                  label="Phone"
                  component={InputPhone}
                  variant="hero"
                />
              </Box>
              <Box mt={6} width={1}>
                <Field
                  name="course"
                  label={t('application-form:applicationForm.fields.bootcamp')}
                  placeholder={t(
                    'application-form:applicationForm.placeholders.course'
                  )}
                  component={InputSelect}
                  variant="hero"
                  options={_.getOr([], campus, courseOptions)}
                />
              </Box>
              <Box mt={6} width={1}>
                <Field
                  name="edition"
                  label={t(
                    'application-form:applicationForm.fields.course_date'
                  )}
                  placeholder={t(
                    'application-form:applicationForm.placeholders.edition'
                  )}
                  component={InputSelect}
                  variant="hero"
                  options={_.getOr([], `${campus}.${course}`, cohortOptions)}
                />
              </Box>
              <Box mt={6} width={1}>
                <Field
                  name="englishlevel"
                  label="What's your level of English?"
                  component={InputSelect}
                  variant="hero"
                  options={englishLevels}
                />
              </Box>
              <Box mt={6} width={1}>
                <Field
                  name="country"
                  label="Where do you live?"
                  component={InputSelect}
                  variant="hero"
                  options={countries}
                />
              </Box>
              <Box mt={6} width={1}>
                <Field
                  name="profile"
                  label="Which profile do you identify with?"
                  component={InputSelect}
                  variant="hero"
                  options={profiles}
                />
              </Box>
              <Box width={1} mt={6}>
                <Field
                  name="whyIronhack"
                  label="Why Are you interested in Ironhack?"
                  component={InputText}
                  variant="retina"
                />
              </Box>
              <Box mt={5} width={1}>
                <Text variant="body5" color="darkBlues.100">
                  {t('application-form:applicationForm.requiredFields')}
                </Text>
              </Box>
              <Box mt={4} width={1}>
                <Field
                  name="acceptPolicy"
                  component={InputCheckbox}
                  variant="hero"
                >
                  <TermsAndConditionsContent />
                </Field>
              </Box>
              <Box mt={4} width={1}>
                <Field name="over18" component={InputCheckbox} variant="hero">
                  {"I'm over 18 years old"}
                </Field>
              </Box>
              <Box mt={4} width={1}>
                <Field
                  name="keepInTouch"
                  component={InputCheckbox}
                  variant="hero"
                >
                  {t('application-form:applicationForm.footer.newsletter')}
                </Field>
              </Box>
              {submitError && !submitting && (
                <Box fontSize={2} mt={6} mx={4} width={1}>
                  <Error
                    pt={5}
                    pb={5}
                    textAlign="center"
                    borderRadius={6}
                    color={'criticals.100'}
                    bg={'criticals.80'}
                  >
                    {submitError}
                  </Error>
                </Box>
              )}
              <Box mt={5} width={1}>
                <SubmitButton
                  type="submit"
                  height={[42, 56]}
                  variant="primary"
                  disabled={error}
                  label={t('application-form:applicationForm.fields.button')}
                  submitting={submitting}
                />
              </Box>
            </Flex>
          </form>
        );
      }}
    />
  );
};

// Validation

const validate = (values: any) => {
  const validForm =
    values.firstName &&
    values.lastName &&
    values.email &&
    values.phone &&
    values.course &&
    values.edition &&
    values.acceptPolicy &&
    /\S+@\S+\.\S+/.test(values.email);

  return validForm ? {} : { [FORM_ERROR]: 'Missing required fields' };
};

// Terms of use content

const TermsAndConditionsContent = () => {
  const { t } = useTranslation(['application-form']);
  return (
    <div>
      {t('application-form:applicationForm.footer.legalAdvice.byApplyingStart')}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={t(
          'application-form:applicationForm.footer.legalAdvice.privacyPolicyLinkHref'
        )}
      >
        &nbsp;
        {t(
          'application-form:applicationForm.footer.legalAdvice.privacyPolicyLinkText'
        )}
        &nbsp;
      </a>
      {t(
        'application-form:applicationForm.footer.legalAdvice.byApplyingConjuntion'
      )}
      &nbsp;
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://drive.google.com/file/d/1FMIiH743u-M1ggd513OrmmlHltFb7cpn/view?usp=sharing`}
      >
        {t(
          'application-form:applicationForm.footer.legalAdvice.termsOfUseLinkText'
        )}
      </a>
    </div>
  );
};

// Aux functions

function buildCohortOptions(languageNames: StringMap, rawCohorts: Cohort[]) {
  return _.pipe(
    _.groupBy('campus_code'),
    _.mapValues(
      _.pipe(
        _.groupBy('course_code'),
        _.mapValues(
          _.map((cohort: Cohort) => ({
            value: cohort.id,
            text: buildCohortText(languageNames, cohort)
          }))
        )
      )
    )
  )(rawCohorts);
}

function buildCourseOptions(cohortOptions: any) {
  return _.mapValues(
    _.pipe(_.keys, cohortCourses =>
      _.filter(
        courseOption =>
          _.contains(_.prop('value', courseOption), cohortCourses),
        courses
      )
    ),
    cohortOptions
  );
}

function buildCohortText(languageNames: StringMap, cohort: Cohort) {
  const startDate = moment(cohort.start_at).format('DD MMM');
  const endDate = moment(cohort.end_at).format('DD MMM');
  const languageName = languageNames[cohort.language];
  return `${startDate} - ${endDate} :: ${languageName}`;
}

const getLanguageNames = (t: Function): StringMap => ({
  en: t('languages:languages.en'),
  es: t('languages:languages.es'),
  fr: t('languages:languages.fr'),
  pt: t('languages:languages.pt'),
  br: t('languages:languages.br'),
  nl: t('languages:languages.nl'),
  de: t('languages:languages.de')
});

const courses: SelectOption[] = [
  { value: 'webft', text: 'Web Development Full-Time' },
  { value: 'webpt', text: 'Web Development Part-Time' },
  { value: 'uxuift', text: 'UX/UI Design Full-Time' },
  { value: 'uxuipt', text: 'UX/UI Design Part-Time' },
  { value: 'dataft', text: 'Data Analytics Full-Time' },
  { value: 'datapt', text: 'Data Analytics Part-Time' },
  { value: 'cysecft', text: 'Cybersecurity Full-Time' },
  { value: 'cysecpt', text: 'Cybersecurity Part-Time' }
];

const englishLevels: SelectOption[] = [
  { value: 'Low', text: 'Low' },
  { value: 'Intermediate', text: 'Intermediate' },
  { value: 'Advanced', text: 'Native' },
  { value: 'I don´t speak English', text: 'I don´t speak English' }
];

const profiles: SelectOption[] = [
  { value: 'Recent graduate', text: 'Recent graduate' },
  { value: 'Career changer', text: 'Career changer' },
  { value: 'Career booster', text: 'Career booster' },
  { value: 'Entrepeneur', text: 'Entrepeneur' },
  { value: 'Unemployed', text: 'Unemployed' },
  { value: 'Other', text: 'Other' },
  { value: 'Personal Development', text: 'Personal Development' },
  { value: 'Student', text: 'Student' }
];

const countries: SelectOption[] = [
  { value: 'Afghanistan', text: 'Afghanistan' },
  { value: 'Åland Islands', text: 'Åland Islands' },
  { value: 'Albania', text: 'Albania' },
  { value: 'Algeria', text: 'Algeria' },
  { value: 'Andorra', text: 'Andorra' },
  { value: 'Angola', text: 'Angola' },
  { value: 'Anguilla', text: 'Anguilla' },
  { value: 'Antigua and Barbuda', text: 'Antigua and Barbuda' },
  { value: 'Argentina', text: 'Argentina' },
  { value: 'Armenia', text: 'Armenia' },
  { value: 'Aruba', text: 'Aruba' },
  { value: 'Ascension Island', text: 'Ascension Island' },
  { value: 'Australia', text: 'Australia' },
  { value: 'Austria', text: 'Austria' },
  { value: 'Azerbaijan', text: 'Azerbaijan' },
  { value: 'Bahamas', text: 'Bahamas' },
  { value: 'Bahrain', text: 'Bahrain' },
  { value: 'Bangladesh', text: 'Bangladesh' },
  { value: 'Barbados', text: 'Barbados' },
  { value: 'Belarus', text: 'Belarus' },
  { value: 'Belgium', text: 'Belgium' },
  { value: 'Belize', text: 'Belize' },
  { value: 'Benin', text: 'Benin' },
  { value: 'Bhutan', text: 'Bhutan' },
  { value: 'Bolivia', text: 'Bolivia' },
  { value: 'Bosnia and Herzegovina', text: 'Bosnia and Herzegovina' },
  { value: 'Botswana', text: 'Botswana' },
  { value: 'Brazil', text: 'Brazil' },
  { value: 'Brazilian', text: 'Brazilian' },
  { value: 'British Virgin Islands', text: 'British Virgin Islands' },
  { value: 'Bulgaria', text: 'Bulgaria' },
  { value: 'Burkina Faso', text: 'Burkina Faso' },
  { value: 'Burundi', text: 'Burundi' },
  { value: 'Cameroon', text: 'Cameroon' },
  { value: 'Canada', text: 'Canada' },
  { value: 'Cape Verde', text: 'Cape Verde' },
  { value: 'Central African Republic', text: 'Central African Republic' },
  { value: 'Chad', text: 'Chad' },
  { value: 'Chile', text: 'Chile' },
  { value: 'China', text: 'China' },
  { value: 'Colombia', text: 'Colombia' },
  { value: 'Comoros', text: 'Comoros' },
  { value: 'Congo - Brazzaville', text: 'Congo - Brazzaville' },
  { value: 'Congo - Kinshasa', text: 'Congo - Kinshasa' },
  { value: 'Costa Rica', text: 'Costa Rica' },
  { value: 'Côte d’Ivoire', text: 'Côte d’Ivoire' },
  { value: 'Croatia', text: 'Croatia' },
  { value: 'Cuba', text: 'Cuba' },
  { value: 'Cyprus', text: 'Cyprus' },
  { value: 'Czech Republic', text: 'Czech Republic' },
  { value: 'Denmark', text: 'Denmark' },
  { value: 'Djibouti', text: 'Djibouti' },
  { value: 'Dominican Republic', text: 'Dominican Republic' },
  { value: 'Ecuador', text: 'Ecuador' },
  { value: 'Egypt', text: 'Egypt' },
  { value: 'El Salvador', text: 'El Salvador' },
  { value: 'England', text: 'England' },
  { value: 'Equatorial Guinea', text: 'Equatorial Guinea' },
  { value: 'Eritrea', text: 'Eritrea' },
  { value: 'España', text: 'España' },
  { value: 'Española', text: 'Española' },
  { value: 'Estonia', text: 'Estonia' },
  { value: 'Ethiopia', text: 'Ethiopia' },
  { value: 'European Union', text: 'European Union' },
  { value: 'Finland', text: 'Finland' },
  { value: 'France', text: 'France' },
  { value: 'French', text: 'French' },
  { value: 'French Guiana', text: 'French Guiana' },
  { value: 'Gabon', text: 'Gabon' },
  { value: 'Georgia', text: 'Georgia' },
  { value: 'German', text: 'German' },
  { value: 'Germany', text: 'Germany' },
  { value: 'Ghana', text: 'Ghana' },
  { value: 'Greece', text: 'Greece' },
  { value: 'Guatemala', text: 'Guatemala' },
  { value: 'Guinea', text: 'Guinea' },
  { value: 'Haiti', text: 'Haiti' },
  { value: 'Honduras', text: 'Honduras' },
  { value: 'Hong Kong', text: 'Hong Kong' },
  { value: 'hungarian', text: 'hungarian' },
  { value: 'Hungary', text: 'Hungary' },
  { value: 'India', text: 'India' },
  { value: 'Iran', text: 'Iran' },
  { value: 'Iraq', text: 'Iraq' },
  { value: 'Ireland', text: 'Ireland' },
  { value: 'Isla de la Ascensión', text: 'Isla de la Ascensión' },
  { value: 'Israel', text: 'Israel' },
  { value: 'Italian', text: 'Italian' },
  { value: 'Italy', text: 'Italy' },
  { value: 'Ivory Coast', text: 'Ivory Coast' },
  { value: 'Jamaica', text: 'Jamaica' },
  { value: 'Japan', text: 'Japan' },
  { value: 'Jordan', text: 'Jordan' },
  { value: 'Kazakhstan', text: 'Kazakhstan' },
  { value: 'Kenya', text: 'Kenya' },
  { value: 'Kosovo', text: 'Kosovo' },
  { value: 'Kuwait', text: 'Kuwait' },
  { value: 'Kyrgyzstan', text: 'Kyrgyzstan' },
  { value: 'Latvia', text: 'Latvia' },
  { value: 'Lebanon', text: 'Lebanon' },
  { value: 'Libya', text: 'Libya' },
  { value: 'Lithuania', text: 'Lithuania' },
  { value: 'Macedonia', text: 'Macedonia' },
  { value: 'Madagascar', text: 'Madagascar' },
  { value: 'Malaysia', text: 'Malaysia' },
  { value: 'Mali', text: 'Mali' },
  { value: 'Marruecos', text: 'Marruecos' },
  { value: 'Mauritania', text: 'Mauritania' },
  { value: 'Mexico', text: 'Mexico' },
  { value: 'Moldova', text: 'Moldova' },
  { value: 'Morocco', text: 'Morocco' },
  { value: 'Myanmar (Burma)', text: 'Myanmar (Burma)' },
  { value: 'Nepal', text: 'Nepal' },
  { value: 'Netherlands', text: 'Netherlands' },
  { value: 'Netherlands Antilles', text: 'Netherlands Antilles' },
  { value: 'New Zealand', text: 'New Zealand' },
  { value: 'Nicaragua', text: 'Nicaragua' },
  { value: 'Niger', text: 'Niger' },
  { value: 'Nigeria', text: 'Nigeria' },
  { value: 'Norway', text: 'Norway' },
  { value: 'Oman', text: 'Oman' },
  { value: 'Pakistan', text: 'Pakistan' },
  { value: 'Palestinian Territories', text: 'Palestinian Territories' },
  { value: 'Panama', text: 'Panama' },
  { value: 'Paraguay', text: 'Paraguay' },
  { value: 'Peru', text: 'Peru' },
  { value: 'Philippines', text: 'Philippines' },
  { value: 'Poland', text: 'Poland' },
  { value: 'Portugal', text: 'Portugal' },
  { value: 'Portuguese', text: 'Portuguese' },
  { value: 'Puerto Rico', text: 'Puerto Rico' },
  { value: 'Qatar', text: 'Qatar' },
  { value: 'Romania', text: 'Romania' },
  { value: 'Russia', text: 'Russia' },
  { value: 'Saudi Arabia', text: 'Saudi Arabia' },
  { value: 'Scotland', text: 'Scotland' },
  { value: 'Scottland', text: 'Scottland' },
  { value: 'Senegal', text: 'Senegal' },
  { value: 'Serbia', text: 'Serbia' },
  { value: 'Singapore', text: 'Singapore' },
  { value: 'Slovakia', text: 'Slovakia' },
  { value: 'Somalia', text: 'Somalia' },
  { value: 'South Africa', text: 'South Africa' },
  { value: 'South Korea', text: 'South Korea' },
  { value: 'Spain', text: 'Spain' },
  { value: 'Spanish', text: 'Spanish' },
  { value: 'Sri Lanka', text: 'Sri Lanka' },
  { value: 'St. Helena', text: 'St. Helena' },
  { value: 'St. Lucia', text: 'St. Lucia' },
  { value: 'Sudan', text: 'Sudan' },
  { value: 'Suriname', text: 'Suriname' },
  { value: 'Sweden', text: 'Sweden' },
  { value: 'Switzerland', text: 'Switzerland' },
  { value: 'Syria', text: 'Syria' },
  { value: 'Taiwan', text: 'Taiwan' },
  { value: 'Togo', text: 'Togo' },
  { value: 'Tristan da Cunha', text: 'Tristan da Cunha' },
  { value: 'Tunisia', text: 'Tunisia' },
  { value: 'Turkey', text: 'Turkey' },
  { value: 'Turks and Caicos Islands', text: 'Turks and Caicos Islands' },
  { value: 'Ucrania', text: 'Ucrania' },
  { value: 'Uganda', text: 'Uganda' },
  { value: 'Ukraine', text: 'Ukraine' },
  { value: 'United Arab Emirates', text: 'United Arab Emirates' },
  { value: 'United Kingdom', text: 'United Kingdom' },
  { value: 'United States', text: 'United States' },
  { value: 'Unknown Region', text: 'Unknown Region' },
  { value: 'Uruguay', text: 'Uruguay' },
  { value: 'Uzbekistan', text: 'Uzbekistan' },
  { value: 'Venezuela', text: 'Venezuela' },
  { value: 'Vietnam', text: 'Vietnam' },
  { value: 'Yemen', text: 'Yemen' },
  { value: 'Rwanda', text: 'Rwanda' }
];

export default ApplyFormLanding;
