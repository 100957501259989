import React from 'react';
import Layout from './styles';
import {
  LandingTextContainer,
  TextContainerItem
} from '../../template/Landing/interfaces';

function TextContainer(props: LandingTextContainer) {
  const { content } = props;

  function getText(texts: string[], parentIndex: number) {
    return texts.map((text: string, index: number) => (
      <p
        key={`text-container-${parentIndex}-${index}`}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    ));
  }

  return (
    <Layout>
      <React.Fragment>
        {content.map((item: TextContainerItem, index: number) => (
          <section key={`text-container-${index}`}>
            <h3>{item.title}</h3>
            {getText(item.text, index)}
          </section>
        ))}
      </React.Fragment>
    </Layout>
  );
}

export default TextContainer;
