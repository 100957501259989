import { documentDefined } from '../../../../lib/utils';
import { getGaConnectorValues } from '../../../../lib/ga-connector';
import { Cohort } from './interfaces';
import { sendZomatoApplicationToPardot } from '../../../../lib/salesforce-integration';

const submitApplyForm = async (
  fieldValues: any,
  selectedCohort: Cohort,
  lang: string
) => {
  const cookies = (documentDefined() && document.cookie) || '';
  const gaConnector = getGaConnectorValues(cookies);

  const salesforceParameters = {
    salesforceId: selectedCohort.salesforce_id,
    date: selectedCohort.start_at,
    gaConnector,
    ...fieldValues
  };
  await sendZomatoApplicationToPardot(
    salesforceParameters,
    lang,
    selectedCohort.timezone
  );
};

export { submitApplyForm };
