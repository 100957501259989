import styled from 'styled-components';
import config from '../../components/config';
import Layout from '../../components/atoms/layout';

export default styled(Layout)`
  margin-bottom: 8rem;
  margin-top: 8rem;

  .testimonial-item {
    align-items: center;
    background: ${config.palette.testimonialBlue};
    border-radius: ${config.borderRadius};
    color: ${config.palette.darkBlueTransparent('0.6')};
    display: flex;
    font-size: 2.4rem;
    font-style: italic;
    justify-content: space-between;
    line-height: 1.5;
    margin-bottom: 3.2rem;
    padding: 4.8rem;
    width: 100%;

    &:nth-child(2n) {
      background: ${config.palette.testimonialYellow};

      & > img {
        margin-left: 6.4rem;
        margin-right: 0;
        order: 2;
      }

      div::before {
        background: url('/landings/default/testimonial-quote-yellow.svg');
      }
    }

    & > img {
      border-radius: ${config.borderRadius};
      margin-right: 6.4rem;
      max-width: 36.8rem;
    }

    strong {
      color: ${config.palette.darkBlue};
      display: block;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      margin-top: 1.6rem;
    }

    div {
      position: relative;

      &::before {
        background: url('/landings/default/testimonial-quote-blue.svg');
        content: '';
        display: block;
        height: 3.2rem;
        left: -0.8rem;
        position: absolute;
        top: -1.6rem;
        width: 3.2rem;
      }
    }
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-bottom: 6.4rem;
    padding: 0;

    h3 {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
    }

    .testimonial-item {
      flex-wrap: wrap;
      font-size: 1.6rem;
      padding: 2.4rem;
      margin-bottom: 0;

      &:nth-child(2n) {
        & > img {
          order: 1;
          margin-left: 0;
        }

        & > div {
          order: 2;
        }
      }

      & > img {
        margin-right: 0;
        max-width: 100%;
      }

      & > div {
        margin-top: 3.2rem;
      }
    }
  }
`;
