import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Layout from './styles';
import { LandingNavbar } from '../../template/Landing/interfaces';
import { scrollFromTop } from '../../lib/utils';

function Navbar(props: LandingNavbar) {
  const { text, linkHref, scrollTo, linkText = 'Apply now' } = props;
  const [isFixed, setIsFixed] = useState(false);
  const [currentNavbarTop, setCurrentNavbarTop] = useState(0);
  const navbarClass = classNames({ active: isFixed });

  function scrollListener() {
    const currentScrollWithMargin = window.scrollY + 30;
    const navbarTop = document.getElementById('navbar-landing').offsetTop;
    if (!isFixed && currentScrollWithMargin > navbarTop) {
      setIsFixed(true);
      setCurrentNavbarTop(navbarTop);
    } else if (currentScrollWithMargin <= currentNavbarTop) {
      setIsFixed(false);
    }
  }

  function getText(paragraphText: string | string[]) {
    if (typeof paragraphText === 'string') {
      return paragraphText;
    }
    return paragraphText.map((textString: string, index: number) => (
      <p key={`nav-${index}`}>{textString}</p>
    ));
  }

  function handleScroll(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (scrollTo) {
      const { elementId, offset } = scrollTo;
      e.preventDefault();
      e.stopPropagation();

      const applyForm = document.getElementById(elementId);
      scrollFromTop((applyForm?.offsetTop || 0) + offset);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  });

  return (
    <Layout id="navbar-landing" className={navbarClass}>
      <article className="content-wrapper">{getText(text)}</article>
      <a
        href={linkHref}
        onClick={handleScroll}
        target="_blank"
        rel="noreferrer noopener"
      >
        {linkText}
      </a>
    </Layout>
  );
}

export default Navbar;
