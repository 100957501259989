import styled from 'styled-components';
import config from '../../components/config';
import Layout from '../../components/atoms/layout';

export default styled(Layout)`
  color: ${config.palette.darkBlueTransparent('0.6')};
  font-size: 2rem;
  line-height: 150%;
  margin-bottom: 16rem;
  margin-top: -8rem;
  position: relative;
  z-index: 10;

  & > section {
    margin-bottom: 4rem;

    h3 {
      margin-bottom: 0;
    }

    b {
      display: block;
      margin-bottom: 0.8rem;
      margin-top: 1.6rem;
    }

    small {
      display: block;
      margin-top: 1.6rem;
    }
  }
`;
