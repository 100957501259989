import React from 'react';
import { CompanyTestimonial } from '../../template/Landing/interfaces';
import Layout from './styles';

function LandingCompanyTestimonial(props: CompanyTestimonial) {
  const { item, customColor = '#2d354c' } = props;

  return (
    <Layout background={customColor}>
      <img src={`/landings/${item.image}`} alt={item.name} />
      <div>
        <strong>{item.name}</strong>
        <span>{item.position}</span>
        {item.description}
      </div>
    </Layout>
  );
}

export default LandingCompanyTestimonial;
