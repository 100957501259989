import styled from 'styled-components';
import config from '../../components/config';

export default styled.section<{ customColor?: string }>`
  color: ${config.palette.darkBlueTransparent('0.6')};

  &.partnership-1 {
    & > header {
      h1 {
        background: ${props => props.customColor};
        border-radius: ${config.borderRadius};
        display: inline-block;
        padding: 0.8rem 3.2rem;
      }
    }

    #navbar-landing {
      a {
        background: ${props => props.customColor};
      }
    }
  }

  &.partnership-2 {
    & > header {
      em {
        background: ${props => props.customColor};
        color: ${config.palette.white};
        padding-left: 0.8rem;
        padding-right: 0.8rem;
      }
    }
  }

  &.extra-text,
  &.is-isa {
    #landing-value-props {
      &::after {
        border-left-color: ${config.palette.white};
      }
    }
  }

  h3 {
    color: ${config.palette.darkBlue};
    font-family: 'Gelion-SemiBold';
    font-size: 4rem;
    letter-spacing: -0.1rem;
    line-height: 1.5;
    margin-bottom: 3.2rem;
    width: 100%;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    padding-bottom: 24rem;

    h3 {
      font-size: 2.4rem;
      margin-bottom: 1.6rem;
    }
  }
`;
